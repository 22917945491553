import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import QRCode from "qrcode";
import OrganizationModal from "../../organizations/modal/organization-modal";
import { Alert } from "@material-ui/lab";
import { alertAdd, fetchOrganizationStart } from "../../../redux/actions";
import { getAxiosInstance } from "../../../redux/common";
import Loader from "../../../components/loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EXAMINATIONS_MAP } from "../../packages/examinations";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { format } from "date-fns/fp";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function getModalStyle() {
  const top = 50;
  const left = 0;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "100vh",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const ScreeningTestModal = ({
  openModal,
  screeningTestToEdit,
  handleClose,
  screeningTestsHandlerType,
  allowedOrgsToAssign,
  setSavedScreeningTest,
  org,
  setAdditionCompleted,
  testStandsToAssign,
  packagesToAssign,
  formsToAssign,
  tenantsContext,
  missingPricelist,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [busy, setBusy] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const [name, setName] = useState(screeningTestToEdit.label || "");
  const [desc, setDesc] = useState(screeningTestToEdit.description || "");
  // const [location, setLocation] = useState(screeningTestToEdit.location || "");
  const [organization, setOrganization] = useState(org || null);
  const [testStands, setTestStands] = useState(
    screeningTestToEdit.hearBoxStations || []
  );
  const [obligatoryForms, setObligatoryForms] = useState([]);
  const [fetchedObligatoryForms, setFetchedObligatoryForms] = useState([]);
  const [optionalForms, setOptionalForms] = useState([]);
  const [fetchedOptionalForms, setFetchedOptionalForms] = useState([]);

  const [packages, setPackages] = useState(
    screeningTestToEdit.examinationPackages || []
  );
  const [type, setType] = useState(screeningTestToEdit.screeningType || []);
  const [startDate, setStartDate] = useState(
    screeningTestToEdit.startDate || ""
  );
  const [endDate, setEndDate] = useState(screeningTestToEdit.endDate || "");
  const [maxAmount, setMaxAmount] = useState(screeningTestToEdit.quantity || 1);
  const [maxAmountUnlimited, setMaxAmountUnlimited] = useState(
    screeningTestToEdit.quantity ? false : true
  );

  const [testType, setTestType] = useState(
    screeningTestToEdit.screeningType || "Free"
  );

  const [selectOpen, setSelectOpen] = useState(false);
  const [selectOpenPackages, setSelectOpenPackages] = useState(false);

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [registerCompleted, setRegisterCompleted] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [savingTest, setSavingTest] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (registerCompleted) {
      setAdditionCompleted(true);
    }
  }, [registerCompleted]);

  useEffect(() => {
    if (
      name &&
      desc &&
      organization &&
      startDate &&
      endDate &&
      (maxAmount || maxAmountUnlimited)
    ) {
      if (screeningTestsHandlerType === "add") {
        setValid(true);
      } else {
        if (
          (testType === "Commercial" ||
            testStands.filter((t) => t).length > 0) &&
          packages.filter((p) => p).length > 0
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
      }
    } else {
      setValid(false);
    }
  }, [
    name,
    desc,
    organization,
    startDate,
    endDate,
    maxAmount,
    maxAmountUnlimited,
    packages,
    testStands,
  ]);
  const handleSelectClose = () => setSelectOpen(false);

  const handleSelectOpen = () => setSelectOpen(true);

  const handleSelectClosePackages = () => setSelectOpenPackages(false);

  const handleSelectOpenPackages = () => setSelectOpenPackages(true);

  const fetchOrganization = () => {};

  const handleTestTypeChange = (event) => {
    setTestType(event.target.name);
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(""), [5000]);
    }
  }, [errorMessage]);

  useEffect(() => {
    (async () => {
      const { screeningId } = screeningTestToEdit;
      if (screeningId) {
        const response = await getAxiosInstance().get(
          `/api/screening_form/owner/?screeningId=${screeningId}`
        );

        setObligatoryForms(
          response.data
            .filter(({ mandatory }) => mandatory)
            .map((form) => {
              return form["formId"];
            })
        );
        setFetchedObligatoryForms(
          response.data
            .filter(({ mandatory }) => mandatory)
            .map((form) => {
              return form;
            })
        );

        setOptionalForms(
          response.data
            .filter(({ mandatory }) => !mandatory)
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => {
                  return t.formId === value.formId;
                })
            )
            .map((form) => {
              return form["formId"];
            })
        );
        setFetchedOptionalForms(
          response.data
            .filter(({ mandatory }) => !mandatory)

            .map((form) => {
              return form;
            })
        );
      }

      setFetchingData(false);
    })();
  }, []);

  const saveHandler = async () => {
    try {
      setSavedScreeningTest(false);
      setSavingTest(true);
      let response = null;
      let responseAddTestStand = [];
      let responseRemoveTestStand = [];
      let responseAddPackage = [];
      let responseRemovePackage = [];
      let responseAddObligatoryForm = [];
      let responseRemoveObligatoryForm = [];
      let responseAddOptionalForm = [];
      let responseRemoveOptionalForm = [];

      if (screeningTestsHandlerType === "edition") {
        try {
          ////////PACKAGE TO ASSIGN/REMOVE ///////
          const packageToAssign = packages.filter(
            (t) => t && !screeningTestToEdit.examinationPackages.includes(t)
          );
          const packageToRemove = screeningTestToEdit.examinationPackages.filter(
            (t) => t && !packages.includes(t)
          );

          for (var i = 0; i < packageToAssign.length; i++) {
            const r = await getAxiosInstance().post(
              `/screening/v1/owner/examination/package`,
              null,
              {
                params: {
                  tenantId: organization.tenantId,
                  screeningId: screeningTestToEdit.screeningId,
                  hearBoxTestId: packageToAssign[i],
                },
              }
            );
            responseAddPackage.push(r);

            for (var j = 0; j < optionalForms.length; j++) {
              const r = await getAxiosInstance().post(
                `/api/screening_form/owner`,
                {
                  screeningId: screeningTestToEdit.screeningId,
                  formId: optionalForms[j],
                  mandatory: false,
                  hearBoxTestId: packageToAssign[i],
                }
              );
              responseAddOptionalForm.push(r);
            }
          }

          for (var i = 0; i < packageToRemove.length; i++) {
            const r = await getAxiosInstance().delete(
              `/screening/v1/owner/examination/package`,
              // null,
              {
                params: {
                  tenantId: organization.tenantId,
                  screeningId: screeningTestToEdit.screeningId,
                  hearBoxTestId: packageToRemove[i],
                },
              }
            );
            responseRemovePackage.push(r);

            const bindingsExaminationFormToRemove = fetchedOptionalForms.filter(
              (el) => el.hearBoxTestId === packageToRemove[i]
            );
            for (var j = 0; j < bindingsExaminationFormToRemove.length; j++) {
              await getAxiosInstance().delete(
                `/api/screening_form/owner/${bindingsExaminationFormToRemove[j].id}`
              );
            }
          }

          ////////TEST STAND TO ASSIGN/REMOVE ///////
          const testStandToAssign = testStands.filter(
            (t) => t && !screeningTestToEdit.hearBoxStations.includes(t)
          );
          const testStandToRemove = screeningTestToEdit.hearBoxStations.filter(
            (t) => t && !testStands.includes(t)
          );

          for (var i = 0; i < testStandToAssign.length; i++) {
            const r = await getAxiosInstance().post(
              `/screening/v1/owner/station`,
              null,
              {
                params: {
                  tenantId: organization.tenantId,
                  screeningId: screeningTestToEdit.screeningId,
                  hearBoxId: testStandToAssign[i],
                },
              }
            );
            responseAddTestStand.push(r);
          }

          for (var i = 0; i < testStandToRemove.length; i++) {
            const r = await getAxiosInstance().delete(
              `/screening/v1/owner/station`,
              // null,
              {
                params: {
                  tenantId: organization.tenantId,
                  screeningId: screeningTestToEdit.screeningId,
                  hearBoxId: testStandToRemove[i],
                },
              }
            );
            responseRemoveTestStand.push(r);
          }

          ////////OBLIGATORY FORMS TO ASSIGN/REMOVE ///////
          const obligatoryFormsToAssign = obligatoryForms.filter(
            (id1) =>
              !fetchedObligatoryForms.some(({ formId: id2 }) => id1 === id2)
          );

          const obligatoryFormsToRemove = fetchedObligatoryForms.filter(
            ({ formId: id1 }) => !obligatoryForms.some((id2) => id1 === id2)
          );

          for (var i = 0; i < obligatoryFormsToAssign.length; i++) {
            const r = await getAxiosInstance().post(
              `/api/screening_form/owner`,
              {
                screeningId: screeningTestToEdit.screeningId,
                formId: obligatoryFormsToAssign[i],
                // mandatory: true,
              }
            );
            responseAddObligatoryForm.push(r);
          }

          for (var i = 0; i < obligatoryFormsToRemove.length; i++) {
            const r = await getAxiosInstance().delete(
              `/api/screening_form/owner/${obligatoryFormsToRemove[i].id}`
            );
            responseRemoveObligatoryForm.push(r);
          }

          ////////OPTIONAL FORMS TO ASSIGN/REMOVE ///////
          const optionalFormsToAssign = optionalForms.filter(
            (id1) =>
              !fetchedOptionalForms.some(({ formId: id2 }) => id1 === id2)
          );

          const optionalFormsToRemove = fetchedOptionalForms.filter(
            ({ formId: id1 }) => !optionalForms.some((id2) => id1 === id2)
          );
          //removing duplicate of packages - already binded with new forms
          const packagesToBind = packages.filter(
            (item) => !packageToAssign.includes(item)
          );
          console.log("packagesToBind", packagesToBind);
          console.log("packageToAssign", packageToAssign);
          for (var j = 0; j < packagesToBind.length; j++) {
            for (var i = 0; i < optionalFormsToAssign.length; i++) {
              const r = await getAxiosInstance().post(
                `/api/screening_form/owner`,
                {
                  screeningId: screeningTestToEdit.screeningId,
                  formId: optionalFormsToAssign[i],
                  mandatory: false,
                  hearBoxTestId: packagesToBind[j],
                }
              );
              responseAddOptionalForm.push(r);
            }
          }

          // for (var j = 0; j < packageToAssign.length; j++) {
          //   for (var i = 0; i < optionalFormsToAssign.length; i++) {
          //     const r = await getAxiosInstance().post(
          //       `/api/screening_form/owner`,
          //       {
          //         screeningId: screeningTestToEdit.screeningId,
          //         formId: optionalFormsToAssign[i],
          //         mandatory: false,
          //         hearBoxTestId: packageToAssign[j],
          //       }
          //     );
          //     responseAddOptionalForm.push(r);
          //   }
          // }

          //removing duplicate of packages - already unbinded with removing forms
          const bindedFormsToUnbind = optionalFormsToRemove.filter(
            (item) => !packageToRemove.includes(item.hearBoxTestId)
          );
          console.log("optionalFormsToRemove", optionalFormsToRemove);
          for (var i = 0; i < bindedFormsToUnbind.length; i++) {
            const r = await getAxiosInstance().delete(
              `/api/screening_form/owner/${bindedFormsToUnbind[i].id}`
            );
            responseRemoveOptionalForm.push(r);
          }

          const domain =
            window.location.hostname === "localhost"
              ? `${window.location.hostname}:${window.location.port}`
              : window.location.hostname;

          const secure = window.location.hostname === "localhost" ? `` : "s";
          let socket = new WebSocket(
            `ws${secure}://${domain}/screening/v1/owner/websocket/connect`
          );

          socket.onopen = async function (e) {
            response = await getAxiosInstance().put(`/screening/v1/owner`, {
              tenantId: organization.tenantId,
              screeningId: screeningTestToEdit.screeningId,
              label: name,
              description: desc,
              startDate,
              endDate,
              ...(!maxAmountUnlimited && { quantity: parseInt(maxAmount) }),
              screeningType: testType,
            });
          };

          socket.onmessage = function (event) {
            if (event.data.includes("ScreeningUpdatedOwnerWebSocketMessage")) {
              setSavedScreeningTest(true);
              setSavingTest(false);
              handleClose();
              socket.close();
            }
          };

          socket.onerror = function (event) {
            setSavingTest(false);
            socket.close();
            setErrorMessage("Wystąpił problem z edycją");
          };
        } catch (e) {
          setErrorMessage("Problem z edycją");
          setSavingTest(false);
        }
      } else {
        const domain =
          window.location.hostname === "localhost"
            ? `${window.location.hostname}:${window.location.port}`
            : window.location.hostname;

        const secure = window.location.hostname === "localhost" ? `` : "s";
        let socket = new WebSocket(
          `ws${secure}://${domain}/screening/v1/owner/websocket/connect`
        );

        socket.onopen = async function (e) {
          response = await getAxiosInstance().post(`/screening/v1/owner`, {
            tenantId: organization.tenantId,
            label: name,
            description: desc,
            startDate,
            endDate,
            // startDate: startDate.toISOString().split("T")[0],
            // endDate: endDate.toISOString().split("T")[0],
            ...(!maxAmountUnlimited && { quantity: parseInt(maxAmount) }),
            screeningType: testType,
          });
        };

        socket.onmessage = function (event) {
          setSavedScreeningTest(true);
          setSavingTest(false);
          handleClose();
          socket.close();
        };

        socket.onclose = function (event) {};

        socket.onerror = function (error) {
          setSavingTest(false);
        };
      }
    } catch (e) {
      console.log(e);
      setSavingTest(false);
      if (
        e.response?.data.msg ===
        "Commercial screening already exists, cannot add second one"
      ) {
        setErrorMessage(
          `Przesiew nie został dodany - komercyjny przesiew już istnieje"
          `
        );
      } else {
        setErrorMessage(
          `Przesiew nie został ${
            screeningTestsHandlerType === "edition" ? "edytowany" : "dodany"
          }`
        );
      }
    }

    // loadScreeningTests();
  };

  const handleOrgChange = (e, v) => {
    if (v) {
      setOrganization(v);
    } else {
      setOrganization(v);
    }
  };

  const handleChange = (e, values) => {
    if (values) {
      setTestStands(
        values.map((v) => (Object.hasOwn(v, "hearBoxId") ? v.hearBoxId : v))
      );
    }
  };

  const handleDelete = (value) => {
    setTestStands(testStands.filter((t) => t !== value));
  };

  const handleChangeObligatoryForm = (e, values) => {
    if (values) {
      setObligatoryForms(
        values.map((v) => (Object.hasOwn(v, "id") ? v.id : v))
      );
    }
  };

  const handleDeleteObligatoryForm = (value) => {
    setObligatoryForms(obligatoryForms.filter((t) => t !== value));
  };

  const handleChangeOptionalForm = (e, values) => {
    if (values) {
      setOptionalForms(values.map((v) => (Object.hasOwn(v, "id") ? v.id : v)));
    }
  };

  const handleDeleteOptionalForm = (value) => {
    setOptionalForms(optionalForms.filter((t) => t !== value));
  };

  const handleChangePackage = (e, values) => {
    if (values) {
      setPackages(
        values.map((v) =>
          Object.hasOwn(v, "hearBoxTestId") ? v.hearBoxTestId : v
        )
      );
    }
  };

  const handleDeletePackage = (value) => {
    setPackages(packages.filter((t) => t !== value));
  };

  const title = (
    <Box mt={1} style={{ display: "flex" }}>
      <Typography variant="h6" gutterBottom>
        {screeningTestsHandlerType === "add"
          ? "Dodaj przesiew"
          : `Edycja przesiewu: ${screeningTestToEdit.label}`}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {openOrganizationModal && (
        <OrganizationModal
          openOrganizationModal={openOrganizationModal}
          organizationHandlerType={"register"}
          handleClose={() => {
            setOpenOrganizationModal(false);
          }}
          setRegisterCompleted={setRegisterCompleted}
          loadOrganizations={fetchOrganization}
        />
      )}
      {registerCompleted && <Alert>Organizacja została założona</Alert>}
      {errorMessage && <Alert severity={"error"}>{errorMessage}</Alert>}
      <Box mt={1}>
        <TextField
          label="Nazwa przesiewu"
          variant="outlined"
          value={name}
          required
          fullWidth
          onChange={({ target: { value: name } }) => {
            setName(name);
          }}
          inputProps={{
            "aria-label": "Nazwa przesiewu",
            maxLength: 255,
          }}
        />
      </Box>
      <Box mt={1}>
        <TextField
          label="Opis"
          placeholder="Opis"
          value={desc}
          required
          fullWidth
          variant="outlined"
          multiline
          rows={2}
          onChange={({ target: { value: desc } }) => {
            setDesc(desc);
          }}
        />
      </Box>
      <Box mt={1}></Box>
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item md={12} lg={9}>
            <Autocomplete
              id="org-assign"
              options={allowedOrgsToAssign}
              value={organization}
              required
              getOptionLabel={(option) => option.orgName || ""}
              disabled={screeningTestsHandlerType === "edition"}
              onChange={handleOrgChange}
              noOptionsText={"Brak wyników"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Przypisz do organizacji"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid
            item
            md={12}
            lg={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => setOpenOrganizationModal(true)}
              disabled={
                screeningTestsHandlerType === "edition" ||
                tenantsContext.length > 0
              }
            >
              Dodaj nową
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={1} style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid container spacing={1}>
          <Grid item md={12} lg={6}>
            {" "}
            <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={startDate ? startDate : null}
                inputVariant="outlined"
                label="Data rozpoczęcia"
                disabled={
                  new Date(screeningTestToEdit?.startDate) < new Date() &&
                  screeningTestsHandlerType === "edition" &&
                  testType === "Free"
                }
                required
                onChange={(date) => {
                  if (endDate) {
                    const start = new Date(date);
                    const end = new Date(endDate);
                    if (start > end) {
                      setStartDate(endDate.toISOString().split("T")[0]);
                    } else {
                      setStartDate(date.toISOString().split("T")[0]);
                    }
                  } else {
                    setStartDate(date.toISOString().split("T")[0]);
                  }
                }}
                keyboardIcon={<EventIcon />}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      disabled={
                        screeningTestsHandlerType === "edition" &&
                        new Date(screeningTestToEdit?.startDate) < new Date()
                      }
                      onClick={() => {
                        setStartDate(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: "start",
                }}
                inputProps={{ readOnly: true }}
                invalidDateMessage=""
                minDateMessage="Data wykracza poza zakres"
                maxDateMessage="Data wykracza poza zakres"
                cancelLabel="Anuluj"
                okLabel="Zatwierdź"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} lg={6}>
            {" "}
            <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={endDate ? endDate : null}
                inputVariant="outlined"
                label="Data zakończenia"
                required
                onChange={(date) => {
                  const end = new Date(date);
                  const start = new Date(startDate);
                  if (start > end) {
                    setEndDate(startDate);
                  } else {
                    setEndDate(date.toISOString().split("T")[0]);
                  }
                }}
                keyboardIcon={<EventIcon />}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        setEndDate(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: "start",
                }}
                inputProps={{ readOnly: true }}
                invalidDateMessage=""
                minDateMessage="Data wykracza poza zakres"
                maxDateMessage="Data wykracza poza zakres"
                cancelLabel="Anuluj"
                okLabel="Zatwierdź"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Box>{" "}
      <Box mt={1} style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <TextField
              label="Ilość"
              fullWidth
              // type="number"
              required
              value={maxAmount}
              disabled={
                (new Date(screeningTestToEdit?.startDate) < new Date() &&
                  screeningTestsHandlerType === "edition") ||
                maxAmountUnlimited
              }
              variant="outlined"
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                let input = e.target.value;
                if (
                  !input ||
                  (input[input.length - 1].match("[0-9]") &&
                    input[0].match("[0-9]"))
                ) {
                  if (!input || input < 1) {
                    setMaxAmount("");
                  } else {
                    setMaxAmount(input);
                  }
                }
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormControlLabel
              disabled={
                new Date(screeningTestToEdit?.startDate) < new Date() &&
                screeningTestsHandlerType === "edition"
              }
              control={
                <Checkbox
                  color="primary"
                  checked={maxAmountUnlimited}
                  onChange={(e) => {
                    // if (!e.target.checked) {
                    //   setMaxAmount(0);
                    // }
                    setMaxAmountUnlimited(e.target.checked);
                  }}
                  name="unlimited"
                />
              }
              label="Bez ograniczeń"
            />
          </Grid>
        </Grid>
      </Box>
      {screeningTestsHandlerType === "edition" && testType == "Free" && (
        <Box mt={1}>
          <Autocomplete
            multiple
            options={testStandsToAssign}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            value={testStands}
            ListboxProps={{
              style: {
                maxHeight: "250px",
              },
            }}
            renderTags={(selected) =>
              testStands.length > 0 && (
                <div style={{ whiteSpace: "break-spaces" }}>
                  {selected.map(
                    (value) =>
                      value && (
                        <Chip
                          key={value}
                          label={
                            testStandsToAssign?.find(
                              (e) => e.hearBoxId === value
                            )?.label
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => handleDelete(value)}
                          className={classes.chip}
                        />
                      )
                  )}
                </div>
              )
            }
            getOptionSelected={(option, value) => option.hearBoxId === value}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Stanowiska"
                placeholder="Dodaj stanowisko"
              />
            )}
          />
        </Box>
      )}
      {screeningTestsHandlerType === "edition" && (
        <Box mt={1}>
          <Autocomplete
            multiple
            options={packagesToAssign}
            // options={packagesToAssign}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            value={packages}
            disabled={
              new Date(screeningTestToEdit?.startDate) < new Date() &&
              screeningTestsHandlerType === "edition" &&
              testType === "Free"
            }
            ListboxProps={{
              style: {
                maxHeight: "250px",
              },
            }}
            renderTags={(selected) =>
              packages.length > 0 && (
                <div style={{ whiteSpace: "break-spaces" }}>
                  {selected.map(
                    (value) =>
                      value && (
                        <Tooltip
                          title={
                            testType === "Commercial" &&
                            missingPricelist.find(
                              (missingPriceList) =>
                                missingPriceList.hearBoxTestId === value
                            )
                              ? "Cennik pakietu jest niepełny"
                              : ""
                          }
                        >
                          <Chip
                            style={{
                              border:
                                testType === "Commercial" &&
                                missingPricelist.find(
                                  (missingPriceList) =>
                                    missingPriceList.hearBoxTestId === value
                                ) &&
                                "1px solid red",
                              color:
                                testType === "Commercial" &&
                                missingPricelist.find(
                                  (missingPriceList) =>
                                    missingPriceList.hearBoxTestId === value
                                ) &&
                                "red",
                            }}
                            key={value}
                            disabled={
                              new Date(screeningTestToEdit?.startDate) <
                                new Date() &&
                              screeningTestsHandlerType === "edition" &&
                              testType === "Free"
                            }
                            label={
                              packagesToAssign?.find(
                                (e) => e.hearBoxTestId === value
                              ).label
                            }
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={() => handleDeletePackage(value)}
                            className={classes.chip}
                          />
                        </Tooltip>
                      )
                  )}
                </div>
              )
            }
            getOptionSelected={(option, value) =>
              option.hearBoxTestId === value
            }
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
            onChange={handleChangePackage}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Pakiety"
                placeholder="Dodaj pakiet"
              />
            )}
          />
        </Box>
      )}
      {screeningTestsHandlerType === "edition" && testType == "Free" && (
        <Box mt={1}>
          <Autocomplete
            multiple
            options={formsToAssign.filter(({ mandatory }) => mandatory)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={obligatoryForms}
            disabled={
              new Date(screeningTestToEdit?.startDate) < new Date() &&
              screeningTestsHandlerType === "edition" &&
              testType === "Free"
            }
            ListboxProps={{
              style: {
                maxHeight: "250px",
              },
            }}
            renderTags={(selected) =>
              obligatoryForms.length > 0 && (
                <div style={{ whiteSpace: "break-spaces" }}>
                  {selected.map(
                    (value) =>
                      value && (
                        <Chip
                          key={value}
                          label={
                            formsToAssign?.find((e) => e.id === value)?.name
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => handleDeleteObligatoryForm(value)}
                          className={classes.chip}
                          disabled={
                            new Date(screeningTestToEdit?.startDate) <
                              new Date() &&
                            screeningTestsHandlerType === "edition" &&
                            testType === "Free"
                          }
                        />
                      )
                  )}
                </div>
              )
            }
            getOptionSelected={(option, value) => option.id === value}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            onChange={handleChangeObligatoryForm}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Ankiety obowiązkowe"
                placeholder="Dodaj ankietę obowiązkową"
              />
            )}
          />
        </Box>
      )}
      {screeningTestsHandlerType === "edition" && (
        <Box mt={1}>
          <Autocomplete
            multiple
            options={formsToAssign.filter(({ mandatory }) => !mandatory)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={optionalForms}
            disabled={
              new Date(screeningTestToEdit?.startDate) < new Date() &&
              screeningTestsHandlerType === "edition" &&
              testType === "Free"
            }
            ListboxProps={{
              style: {
                maxHeight: "250px",
              },
            }}
            renderTags={(selected) =>
              optionalForms.length > 0 && (
                <div style={{ whiteSpace: "break-spaces" }}>
                  {selected.map(
                    (value) =>
                      value && (
                        <Chip
                          key={value}
                          label={
                            formsToAssign?.find((e) => e.id === value)?.name
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => handleDeleteOptionalForm(value)}
                          className={classes.chip}
                          disabled={
                            new Date(screeningTestToEdit?.startDate) <
                              new Date() &&
                            screeningTestsHandlerType === "edition" &&
                            testType === "Free"
                          }
                        />
                      )
                  )}
                </div>
              )
            }
            getOptionSelected={(option, value) => option.id === value}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            onChange={handleChangeOptionalForm}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Ankiety opcjonalne"
                placeholder="Dodaj ankietę opcjonalną"
              />
            )}
          />
        </Box>
      )}
    </Box>
  );

  const foot = (
    <Grid>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={"contained"}
          disabled={savingTest}
          onClick={() => handleClose()}
        >
          Anuluj
        </Button>
        &nbsp;&nbsp;&nbsp;{" "}
        <Button
          variant={"contained"}
          color="primary"
          disabled={savingTest || !valid}
          onClick={saveHandler}
        >
          {busy && <CircularProgress size={14} />}
          Zapisz
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal open={openModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {!savingTest && !fetchingData ? (
              body
            ) : (
              <Loader
                loading={true}
                text={
                  fetchingData
                    ? "Pobieranie danych - proszę czekać"
                    : "Zapis przesiewu - proszę czekać"
                }
              ></Loader>
            )}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default ScreeningTestModal;
