import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import FilterProperty from "../../components/filter-property/filter-property";
import {
  activateForm,
  duplicateForm,
  fetchForms,
  removeForm,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "./modal/form-modal";
import { NavLink, useHistory } from "react-router-dom";
import SingleForm from "../../pages/FormsPage/single-form";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const FormsAdminPanel = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filterQueries, setFilterQueries] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("label");

  const forms = useSelector((s) => s.orgForms);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchForms({
        page: 0,
        pageSize: 100,
      })
    );
  }, []);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const remove = (id) => {
    const r = dispatch(removeForm(id));
  };

  const edit = (id) => {
    history.push(`/admin-panel/survey/edit/${id}`);
  };

  const display = (id) => {
    history.push(`/admin-panel/survey/display/${id}`);
  };

  const headerCells = [
    {
      title: "Nazwa",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    // {
    //   title: "Project",
    //   key: "projectName",
    //   sortable: true,
    //   filter: (key) => (
    //     <FilterProperty
    //       filterProperty={key}
    //       setFilterQueries={setFilterQueries}
    //       filterQueries={filterQueries}
    //     />
    //   ),
    // },

    {
      title: "Odpowiedzi",
      key: "answerCount",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,

      filter: (key) => <></>,
    },
  ];

  return (
    <div style={{ padding: 10 }}>
      {" "}
      <NavLink to="/admin-panel/survey/add">
        <Button variant={"contained"}>Dodaj nową ankietę</Button>
      </NavLink>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Tooltip title="Sortuj">
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(headCell.key);
                        }}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {forms.map((form, index) => {
              return (
                <SingleForm
                  filledFormsDisplay={false}
                  key={index}
                  form={form}
                  remove={remove}
                  edit={edit}
                  display={display}
                  activateForm={(form) => dispatch(activateForm(form))}
                  duplicateForm={(id) => dispatch(duplicateForm(id))}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FormsAdminPanel;
