import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Tooltip,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import { getAxiosInstance } from "../../../redux/common";
import { ResultsRow } from "../../../components/detailed-results/results-per-package-table";
import { RESULT_MAP } from "../desciption-lock";
import { MAPDoctorTitle } from "../../../super-admin/users/user-row";
import { checkAuthMedic } from "../../../redux/admin/actions";
import { alertAdd } from "../../../redux/actions";
import ResultsMapper from "../../../components/detailed-results/results-mapper";
import GeneratePDF from "../../../components/detailed-results/generate-pdf";
import Loader from "../../../components/loader";
import SurveyResultsModal from "../../../components/survey-results-modal";

export const useStyles = makeStyles((theme) => ({
  editionButton: {
    padding: "0px 16px",
    borderRadius: "20px",
    letterSpacing: "0.5px",
    background: theme.palette.lockIcon.primary,
    color: theme.palette.background.default,
    fontSize: theme.typography.footerMobile,
    "&:hover": {
      background: theme.palette.passwordIcon.primary,
    },
  },
}));
const ResultsPerPackageTableDoctor = () => {
  const history = useHistory();
  if (!history.location.state) {
    history.push({ pathname: "/admin-panel/examinations-described" });
  }
  const {
    patientScreeningId,
    hearBoxScreeningId,
    hearBoxTestId,
    patient,
    screeningId,
    screeningType,
  } = history.location.state ?? {};
  const me = useSelector((s) => s.myDoctor);
  const [entry, setEntry] = useState();
  const [description, setDescription] = useState({
    savedDoctorDescription: "",
    finished: "",
    newDoctorDescription: "",
  });
  const classes = useStyles();
  const [edited, setEdited] = useState(false);
  const [isDocument, setDocument] = useState(false);
  const patientName = `${patient?.firstName} ${patient?.lastName}` || "---";
  const [saving, setSaving] = useState(false);
  const [displayDetailedResult, setDisplayDetailedResult] = useState();
  const [downloading, setDownloading] = useState(false);
  const [pdfData, setPdfData] = useState(undefined);
  const dispatch = useDispatch();
  const pdfRef = useRef();
  const [ready, setReady] = useState(false);
  const audiometry = useMemo(
    () => entry?.data?.testsData?.find(({ testType }) => testType === "AP"),
    [entry]
  );

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [params, setParams] = useState();

  const [openSurveyModal, setOpenSurveyModal] = useState();

  const handleDownloadPdfClick = async () => {
    setDownloading(true);
    await pdfRef.current.downloadPDF();
    setDownloading(false);
  };

  const sendDataToPDFIfMissing = () => {
    if (description.finished === true && description.ts && pdfData === null) {
      setSaving(true);
      const docsData = {
        patient,
        data: entry.data,
        result: entry.header.result,
        doctorDescription: {
          description: description.savedDoctorDescription,
          signature: `${MAPDoctorTitle[me.titles[0]]} ${me.name}`,
          ts: description.ts,
        },
      };
      getAxiosInstance()
        .post("/api/test_description/medic", {
          patientScreeningId,
          patientId: patient.id,
          hearBoxScreeningId,
          testData: docsData,
        })
        .then(() => {
          setPdfData(docsData);
          dispatch(
            alertAdd({
              text: "Pomyślnie wysłano dane do generacji dokumentu.",
              isSuccess: true,
            })
          );
        })
        .catch(() =>
          dispatch(
            alertAdd({
              text: "Nie udało się wysłać danych do generacji dokumentu.",
              isError: true,
            })
          )
        )
        .finally(() => setSaving(false));
    }
  };

  useEffect(() => {
    me === null && dispatch(checkAuthMedic());
  }, []);

  useEffect(() => {
    if (patientScreeningId && hearBoxScreeningId && patient && hearBoxTestId) {
      getAxiosInstance()
        .get(`/patient/v1/medic/screening/completed/entry`, {
          params: {
            patientScreeningId,
            hearBoxScreeningId,
            patientId: patient.id,
          },
        })
        .then((result) => {
          setEntry(result.data);
        });

      getAxiosInstance()
        .get(`/patient/v1/medic/screening/completed/description/entry`, {
          params: {
            patientScreeningId,
            hearBoxTestId,
            patientId: patient.id,
          },
        })
        .then((result) => {
          setDescription({
            savedDoctorDescription: result.data.doctorDescription,
            newDoctorDescription: result.data.doctorDescription,
            finished: result.data.finished,
            ts: result.data.ts,
          });
          if (result.data.finished === true) {
            getAxiosInstance()
              .get(`/api/test_description/medic/${hearBoxScreeningId}`)
              .then((result) => {
                setPdfData(result.data.testData);
              })
              .catch(() => {
                setPdfData(null);
              })
              .finally(() => {
                setDocument(true);
                setReady(true);
              });
          } else {
            setReady(true);
          }
        })
        .catch(() => {
          setReady(true);
        });
    }
  }, [patientScreeningId, hearBoxScreeningId, patient, hearBoxTestId]);

  const handleChange = (description) => {
    setEdited(true);
    setDescription((prevState) => ({
      ...prevState,
      newDoctorDescription: description,
    }));
  };

  const handleCancelEdit = () => {
    setEdited(false);
    setDescription((prevState) => ({
      ...prevState,
      newDoctorDescription: prevState.savedDoctorDescription,
    }));
  };

  const saveDescription = async (finished = false) => {
    try {
      setSaving(true);
      // const domain =
      //   window.location.hostname === "localhost"
      //     ? `${window.location.hostname}:${window.location.port}`
      //     : window.location.hostname;

      // const secure = window.location.hostname === "localhost" ? `` : "s";
      // let socket = new WebSocket(
      //   `ws${secure}://${domain}/medic/v1/doctor/websocket/connect`
      // );

      // socket.onopen = async function (e) {
      try {
        await getAxiosInstance().post(
          `/patient/v1/medic/screening/completed/description/finish`,
          {
            patientScreeningId,
            hearBoxTestId,
            patientId: patient.id,
            hearBoxScreeningId,
            doctorDescription: description.newDoctorDescription,
            finished,
          }
        );
        if (finished === true) {
          const docsData = {
            patient,
            data: entry.data,
            result: entry.header.result,
            doctorDescription: {
              description: description.newDoctorDescription,
              signature: `${MAPDoctorTitle[me.titles[0]]} ${me.name}`,
              ts: moment().toISOString(),
            },
          };
          await getAxiosInstance().post("/api/test_description/medic", {
            patientScreeningId,
            patientId: patient.id,
            hearBoxScreeningId,
            testData: docsData,
          });
          setPdfData(docsData);
        }

        setSaving(false);
        setEdited(false);
        setDescription((prevState) => ({
          ...prevState,
          savedDoctorDescription: prevState.newDoctorDescription,
          finished,
        }));
        dispatch(
          alertAdd({
            text: finished
              ? "Pomyślnie wysłano opis."
              : "Pomyślnie zapisano opis.",
            isSuccess: true,
          })
        );
      } catch (error) {
        setSaving(false);
        dispatch(
          alertAdd({
            text: "Wystąpił błąd z wysłaniem opisu lekarskiego.",
            isError: true,
          })
        );
      }
      //};

      //socket.onmessage = function (event) {
      // setSaving(false);
      // setEdited(false);
      // setDescription((prevState) => ({
      //   ...prevState,
      //   savedDoctorDescription: prevState.newDoctorDescription,
      //   finished,
      // }));
      // dispatch(
      //   alertAdd({
      //     text: finished
      //       ? "Pomyślnie wysłano opis."
      //       : "Pomyślnie zapisano opis.",
      //     isSuccess: true,
      //   })
      // );
      //   socket.close();
      // };

      // socket.onerror = function (event) {
      //   setSaving(false);
      //   dispatch(
      //     alertAdd({
      //       text: "Wystąpił błąd z zapisaniem opisu lekarskiego.",
      //       isError: true,
      //     })
      //   );
      //   socket.close();
      // };
    } catch (error) {
      setSaving(false);
      dispatch(
        alertAdd({
          text: "Wystąpił błąd z wysłaniem opisu lekarskiego.",
          isError: true,
        })
      );
    }
  };

  if (!ready) {
    return <Loader loading={true} text="Pobieranie danych..." />;
  }
  if (!entry) {
    return null;
  }
  return displayDetailedResult ? (
    <ResultsMapper
      test={displayDetailedResult}
      handleChangeDisplayedResults={setDisplayDetailedResult}
      audiometry={audiometry}
    />
  ) : (
    <Box
      style={{
        margin: 8,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Prompt
        when={edited}
        message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
      />
      {isDocument && description.finished && pdfData && (
        <Button
          variant="contained"
          style={{
            margin: "auto",
            backgroundColor: "#1E90FF",
            color: "white",
            marginTop: 50,
          }}
          disabled={downloading}
          onClick={() => handleDownloadPdfClick()}
          endIcon={downloading ? <CircularProgress size={24} /> : ""}
        >
          {downloading ? "Pobieranie" : "Pobierz PDF"}
        </Button>
      )}
      {isDocument
        ? !description.finished && (
            <Grid
              container
              justify="space-evenly"
              spacing={2}
              style={{
                display: "flex",
                justifyContent: smallScreen && "space-between",
                marginTop: smallScreen ? "16px" : "50px",
                marginBottom: "10px",
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: !smallScreen && "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => setDocument(false)}
                >
                  Popraw
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  disabled={saving}
                  style={{
                    backgroundColor: "#1E90FF",
                    color: "white",
                  }}
                  onClick={() => saveDescription(true)}
                >
                  {saving ? (
                    <CircularProgress
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    "Wyślij"
                  )}
                </Button>
              </Grid>
            </Grid>
          )
        : !description.finished && (
            <Grid
              container
              spacing={2}
              justify="space-evenly"
              style={{
                display: "flex",
                flexDirection: smallScreen && "column",
                marginTop: smallScreen ? "16px" : "50px",
                marginBottom: "10px",
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: !smallScreen && "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: edited ? "#1E90FF" : "",
                    color: "white",
                  }}
                  disabled={!edited || saving}
                  onClick={() => saveDescription()}
                >
                  {saving ? (
                    <CircularProgress
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    "Zapisz"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: edited ? "red" : "",
                    color: "white",
                  }}
                  disabled={!edited || saving}
                  onClick={() => handleCancelEdit()}
                >
                  {saving ? (
                    <CircularProgress
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    "Anuluj"
                  )}
                </Button>
                {edited ? (
                  saving ? (
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        marginLeft: 16,
                      }}
                    >
                      <CircularProgress
                        style={{ height: "30px", width: "30px" }}
                      />
                    </Button>
                  ) : (
                    <Tooltip title="Aby wygenerować dokument zapisz zmiany.">
                      <Button
                        variant="contained"
                        style={{
                          pointerEvents: "auto",
                          color: "white",
                          marginLeft: 16,
                        }}
                        disabled
                      >
                        Generuj dokument
                      </Button>
                    </Tooltip>
                  )
                ) : (
                  <Button
                    variant="contained"
                    disabled={description.newDoctorDescription.length === 0}
                    style={{
                      backgroundColor:
                        description.newDoctorDescription.length === 0
                          ? ""
                          : "#1E90FF",
                      color: "white",
                      marginLeft: 16,
                    }}
                    onClick={() => {
                      setDocument(true);
                    }}
                  >
                    Generuj dokument
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
      {isDocument &&
        description.finished &&
        pdfData === null &&
        description.ts && (
          <Button
            variant="contained"
            style={{
              margin: "auto",
              backgroundColor: "#1E90FF",
              color: "white",
            }}
            disabled={saving}
            onClick={() => sendDataToPDFIfMissing()}
            endIcon={saving ? <CircularProgress size={24} /> : ""}
          >
            {saving ? "Zapisywanie" : "Zapisz dane do dokumentu"}
          </Button>
        )}
      <Typography variant="h6">{`Dane pacjenta: ${patientName}, data urodzenia: ${moment(
        new Date(`${patient.monthOfBirth}/01/${patient.yearOfBirth}`)
      ).format("MM/YYYY")}`}</Typography>
      <Typography variant="h6">{`Wypełnione dnia: od ${moment
        .utc(entry.data.startTs)
        .local()
        .format("DD/MM/YYYY HH:mm")} do ${moment
        .utc(entry.data.finishTs)
        .local()
        .format("DD/MM/YYYY HH:mm")}`}</Typography>
      <br />
      <Button
        variant="contained"
        className={classes.editionButton}
        onClick={() => setOpenSurveyModal(true)}
      >
        Wypełnione ankiety
      </Button>{" "}
      <br />
      <Typography variant="h6">{`Ogólny wynik pakietu badań: ${
        RESULT_MAP[entry.header.result]
      }`}</Typography>
      <br />
      <Typography variant="h5">Wyniki szczegółowe:</Typography>
      <TableContainer style={{ marginBottom: 32, marginTop: 18 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Rodzaj testu</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography align="center">Wynik ucha prawego</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography align="center">Wynik ucha lewego</Typography>
              </TableCell>
              {!isDocument && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {entry.data.testsData.map((testData, index) => (
              <ResultsRow
                key={index}
                testData={testData}
                forDocument={isDocument}
                handleChangeDisplayedResults={setDisplayDetailedResult}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            disabled={isDocument || description.finished}
            multiline={true}
            label="Opis badania"
            value={description.newDoctorDescription}
            onChange={({ target: { value: value } }) => handleChange(value)}
            fullWidth
          />
        </Grid>
      </Grid>
      {isDocument && me && (
        <Typography variant="h6">{`${MAPDoctorTitle[me.titles[0]]} ${
          me.name
        }`}</Typography>
      )}
      {isDocument && (
        <Box style={{ marginBottom: 64, marginTop: 18 }}>
          {entry.data.testsData.map(
            (testData, index) =>
              testData.data && (
                <ResultsMapper
                  key={index}
                  test={testData}
                  forDocument={isDocument}
                  audiometry={audiometry}
                />
              )
          )}
        </Box>
      )}
      {openSurveyModal && (
        <SurveyResultsModal
          handleClose={() => setOpenSurveyModal(false)}
          open={openSurveyModal}
          params={{ screeningId }}
          isDoctor={true}
          patient={patient}
          hearBoxTestId={hearBoxTestId}
          hearBoxScreeningId={hearBoxScreeningId}
          patientScreeningId={patientScreeningId}
          screeningType={screeningType}
        />
      )}
      {isDocument && description.finished && pdfData && (
        <GeneratePDF
          ref={pdfRef}
          data={pdfData.data}
          doctorDescription={pdfData.doctorDescription}
          patient={pdfData.patient}
          result={pdfData.result}
        />
      )}
    </Box>
  );
};

export default ResultsPerPackageTableDoctor;
