import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/loader";
import { getAxiosInstance } from "../../../redux/common";
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { format } from "date-fns";
import moment from "moment/moment";
import { fetchPackages, fetchScreeningInfo } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { checkAuthMedic } from "../../../redux/admin/actions";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../../components/filter-property/filter-property";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
// import TestStandModal from "./modal/test-stand-modal";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
}));

export const RESULT_MAP = {
  AMB: "Niejednoznaczny",
  OK: "Poprawny",
  NOK: "Niepoprawny",
};

const DescriptionLockDoctorPanel = () => {
  const classes = useStyles();

  const [fetchingDescriptions, setFetchingDescriptions] = useState(true);
  const [packages, setPackages] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [fetchingPackages, setFetchingPackages] = useState(false);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("description_lock_ts");

  const pages = [5, 10, 20, 50];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [filterQueries, setFilterQueries] = useState({});

  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const [lockedDescriptions, setLockedDescriptions] = useState([]);
  const [lockedDescriptionsCount, setLockedDescriptionsCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    loadDescriptions();
  }, [pageNr, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function objToString(obj) {
    return Object.entries(obj).reduce((str, [p, val]) => {
      return `${str}${p}::${val}\n`;
    }, "");
  }

  const loadDescriptions = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingDescriptions(true);
      const titleResponse = await dispatch(checkAuthMedic());
      let response = null;
      if (Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty) {
        const q = Object.entries(filterQueries).map(([key, value]) => {
          return {
            key: key,
            value:
              key === "birthdaySearch" ? value.replaceAll("/", "-") : value,
          };
        });

        const newQuery = q.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        );
        console.log("q", newQuery);
        response = await getAxiosInstance().get(
          "/medic/v1/patient/description/my/locked/paginated",
          {
            params: {
              title: titleResponse.data.titles[0],
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
              ...newQuery,
            },
          }
        );
      } else {
        response = await getAxiosInstance().get(
          "/medic/v1/patient/description/my/locked/paginated",
          {
            params: {
              title: titleResponse.data.titles[0],
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
            },
          }
        );
      }

      setLockedDescriptions(response.data.results);
      setLockedDescriptionsCount(response.data.allResults);
      setFetchingDescriptions(false);
    } catch (e) {
      setFetchingDescriptions(false);
      setPackages([]);
    }
  };

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    setPageNr(0);
    loadDescriptions();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    setPageNr(0);
    loadDescriptions(true);
  };

  const headerCells = [
    {
      title: "Pacjent",
      key: "patientSearch",
      keySort: "patient",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Data urodzenia",
      key: "birthdaySearch",
      keySort: "birthday",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
          onlyYear
        />
      ),
    },
    {
      title: "Przesiew",
      key: "screeningSearch",
      keySort: "screening",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "examinationPackageSearch",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Wynik Ogólny",
      key: "resultSearch",
      keySort: "general_result",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Wynik"}
          listItems={[
            { value: "OK", label: "Poprawny" },
            { value: "NOK", label: "Niepoprawny" },
            { value: "AMB", label: "Niejednoznaczny" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas wypełnienia badania",
      key: "examinationTsSearch",
      keySort: "examination_ts",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas zamówienia opisu",
      key: "descriptionRequestTsSearch",
      keySort: "description_request_ts",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas przypisania opisu",
      key: "descriptionLockTsSearch",
      keySort: "description_lock_ts",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "anonymizationRequestedAt",
      sortable: true,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const DescRow = ({
    desc: {
      screening: { label, screeningType },
      examinationPackage,
      hearBoxScreeningHeader: {
        patient,
        result,
        ts,
        patientScreeningId,
        hearBoxTestId,
        hearBoxScreeningId,
        screeningId,
      },
      lockedMedicDescription,
    },
  }) => {
    const history = useHistory();
    return (
      <TableRow>
        {" "}
        <TableCell>{`${patient.firstName} ${patient.lastName}`}</TableCell>
        <TableCell>
          {moment(
            new Date(`${patient.monthOfBirth}/01/${patient.yearOfBirth}`)
          ).format("MM/YYYY")}
          {/* {moment(new Date(patient.yearOfBirth, patient.monthOfBirth))} */}
        </TableCell>
        <TableCell>{label}</TableCell>
        <TableCell>
          <p style={{ marginTop: "5px" }}>{examinationPackage.label}</p>
        </TableCell>
        <TableCell>{RESULT_MAP[result]}</TableCell>
        <TableCell>
          {moment.utc(ts).local().format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          {moment
            .utc(lockedMedicDescription.descriptionRequestTs)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          {moment
            .utc(lockedMedicDescription.ts)
            .local()
            .format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              history.push({
                pathname: "/admin-panel/description-fill",
                state: {
                  hearBoxTestId,
                  patientScreeningId,
                  hearBoxScreeningId,
                  packageName: examinationPackage.label,
                  patient,
                  screeningId,
                  screeningType,
                },
              });
            }}
          >
            Opisz
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return fetchingDescriptions ? (
    <Loader loading={true} text="Pobieranie opisów" />
  ) : (
    <Box p={3}>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.keySort}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.keySort}
                      direction={orderBy === headCell.keySort ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.keySort);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lockedDescriptionsCount === 0 ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h1>Brak opisów</h1>
                </TableCell>
              </TableRow>
            ) : (
              lockedDescriptions.map((desc, index) => (
                <DescRow key={index} desc={desc} />
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          // count={patients.length}
          count={lockedDescriptionsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        />
      </TableContainer>
    </Box>
  );
};

export default DescriptionLockDoctorPanel;
