import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/loader";
import { getAxiosInstance } from "../../../redux/common";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { format } from "date-fns";
import moment from "moment/moment";
import { fetchPackages, fetchScreeningInfo } from "../../../redux/actions";
import Alert from "@material-ui/lab/Alert";
import FilterProperty, {
  FilterPropertyDate,
  FilterPropertySelect,
} from "../../../components/filter-property/filter-property";
import { checkAuthMedic } from "../../../redux/admin/actions";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
// import TestStandModal from "./modal/test-stand-modal";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
}));

const FinishedDescriptionsDoctorPanel = () => {
  const history = useHistory();
  const classes = useStyles();

  const [fetchingDescriptions, setFetchingDescriptions] = useState(true);
  const [finishedDescriptions, setFinishedDescriptions] = useState([]);
  const [finishedDescriptionsCount, setFinishedDescriptionsCount] = useState(0);

  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("description_ts");
  const [filterQueries, setFilterQueries] = useState({});

  const pages = [5, 10, 20, 50];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    loadDescriptions();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => setErrorMessage(""), 5000);
    }
  }, [errorMessage]);

  useEffect(() => {
    loadDescriptions();
  }, [pageNr, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const dispatch = useDispatch();

  const loadDescriptions = async (areFilterQueriesEmpty = false) => {
    try {
      setFetchingDescriptions(true);
      const titleResponse = await dispatch(checkAuthMedic());
      let response = null;
      if (Object.keys(filterQueries).length > 0 && !areFilterQueriesEmpty) {
        const q = Object.entries(filterQueries).map(([key, value]) => {
          return {
            key,
            value:
              key === "birthdaySearch" ? value.replaceAll("/", "-") : value,
          };
        });

        const newQuery = q.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        );
        response = await getAxiosInstance().get(
          "/medic/v1/patient/description/my/finished/paginated",
          {
            params: {
              title: titleResponse.data.titles[0],
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
              ...newQuery,
            },
          }
        );
      } else {
        response = await getAxiosInstance().get(
          "/medic/v1/patient/description/my/finished/paginated",
          {
            params: {
              title: titleResponse.data.titles[0],
              page: pageNr,
              limit: rowsPerPage,
              sortBy: orderBy,
              sortDir: order,
            },
          }
        );
      }

      setFinishedDescriptions(response.data.descriptions);
      setFinishedDescriptionsCount(response.data.allDescriptions);
      setFetchingDescriptions(false);
    } catch (e) {
      setFetchingDescriptions(false);
    }
  };

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    setPageNr(0);
    loadDescriptions();
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    setPageNr(0);
    loadDescriptions(true);
  };

  const headerCells = [
    {
      title: "Pacjent",
      key: "patientSearch",
      keySort: "patient",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Rok urodzenia",
      key: "birthdaySearch",
      keySort: "birthday",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
          onlyYear
        />
      ),
    },
    {
      title: "Przesiew",
      key: "screeningSearch",
      keySort: "screening",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Pakiety",
      key: "examinationPackageSearch",
      sortable: false,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Czas wykonania opisu",
      key: "descriptionTsSearch",
      keySort: "description_ts",
      sortable: true,
      filter: (key) => (
        <FilterPropertyDate
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "option",
      sortable: false,
      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const PackageRow = ({
    simplePackage: {
      description: {
        patientFirstName,
        patientLastName,
        patientMonthOfBirth,
        patientYearOfBirth,
        patientId,
        patientScreeningId,
        hearBoxScreeningId,
        ts,
      },
      examinationPackage,
      screening: { label, screeningId },
    },
  }) => {
    return (
      <TableRow>
        {" "}
        <TableCell>{`${patientFirstName} ${patientLastName}`}</TableCell>
        <TableCell>
          {moment(
            new Date(`${patientMonthOfBirth}/01/${patientYearOfBirth}`)
          ).format("MM/YYYY")}
        </TableCell>
        <TableCell>{label}</TableCell>
        <TableCell>
          <p style={{ marginTop: "5px" }}>{examinationPackage.label}</p>
        </TableCell>
        <TableCell>
          {moment.utc(ts).local().format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              history.push({
                pathname: "/admin-panel/description-fill",
                state: {
                  screeningId,
                  hearBoxTestId: examinationPackage.hearBoxTestId,
                  patientScreeningId,
                  hearBoxScreeningId,
                  packageName: examinationPackage.label,
                  patient: {
                    id: patientId,
                    firstName: patientFirstName,
                    lastName: patientLastName,
                    monthOfBirth: patientMonthOfBirth,
                    yearOfBirth: patientYearOfBirth,
                  },
                },
              });
            }}
          >
            Szczegóły
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return fetchingDescriptions ? (
    <Loader loading={true} text="Pobieranie wykonanych opisów" />
  ) : (
    <Box p={3}>
      {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell, index) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.keySort}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.keySort}
                      direction={orderBy === headCell.keySort ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.keySort);
                      }}
                    >
                      {headCell.title}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell, index) => (
                <TableCell key={index}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {" "}
            {console.log("finishedDescriptions", finishedDescriptions)}
            {finishedDescriptionsCount === 0 ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h1>Brak opisów</h1>
                </TableCell>
              </TableRow>
            ) : (
              finishedDescriptions.map((desc, index) => (
                <PackageRow key={index} simplePackage={desc} />
              ))
            )}
          </TableBody>
        </Table>{" "}
        <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          // count={patients.length}
          count={finishedDescriptionsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        />
      </TableContainer>
    </Box>
  );
};

export default FinishedDescriptionsDoctorPanel;
