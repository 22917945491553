import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
}));

const PatientRow = ({
  patient,
  setOpenUserModal,
  setUserToEdit,
  setUserHandlerType,
  userRemoveHandler,
}) => {
  const classes = useStyles();

  const { firstName, surname, mail, anonymizationRequestedAt, id } = patient;

  return (
    <TableRow>
      <TableCell>{firstName || "---"}</TableCell>
      <TableCell>{surname || "---"}</TableCell>
      <TableCell>{mail || "---"}</TableCell>

      <TableCell>
        {anonymizationRequestedAt && (
          <Button
            variant={"contained"}
            color="secondary"
            onClick={() => userRemoveHandler(id)}
          >
            Usuń
          </Button>
        )}
      </TableCell>
      <TableCell>
        <NavLink to={`/admin-panel/examinations-executed/user/${id}`}>
          <Button
          // onClick={() => userRemoveHandler(id)}
          >
            Wykonane badania
          </Button>{" "}
        </NavLink>
      </TableCell>
    </TableRow>
  );
};

export default PatientRow;
