import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { alertAdd, resetByMailPassword, logout } from "../../redux/actions";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Box, Paper, Typography } from "@material-ui/core";
import { VisibilityOutlined as Visibility } from "@material-ui/icons";
import { ReactComponent as LockOpen } from "../../assets/lock-open.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Can from "../../components/can";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import s from "../register-organization/index.module.css";
import Loader from "../../components/loader";
import { parse } from "qs";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

export const CssTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: theme.palette.text.hintText,
    },
    "& label.Mui-focused": {
      borderColor: theme.palette.primary.main,
      marginRight: "10px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "32px",
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
    textAlign: "left",
  },
  formHint1: {
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  lock: {
    color: theme.palette.lockIcon.primary,
    marginRight: "5px",
  },
  card: {
    minWidth: 250,
    borderRadius: "1rem",
    border: `0.5px solid ${theme.palette.hr.primary}`,
    boxShadow: "none",
  },
  mainGrid: {
    justifyContent: "center",
  },
  iconColor: {
    color: theme.palette.passwordIcon2.primary,
  },
  hr: {
    opacity: "0.5",
    margin: "2.5em 20px 2.5em 60px",
    border: `0.5px solid ${theme.palette.text.whiteHeader}`,
    [theme.breakpoints.down("lg")]: {
      margin: "1.5em 0 1.5em 0",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  },
  locationButton: {
    marginTop: "3em",
    backgroundColor: theme.palette.background.orderButton,
    color: theme.palette.background.default,
    borderRadius: "30px",
    fontWeight: "700",
    fontSize: theme.typography.footerMobile,
    padding: "0px",
    width: `190px`,
    height: "50px",
  },
  paper: {
    border: `0.5px solid ${theme.palette.hr.primary}`,
    padding: "32px",
    textAlign: "center",
    borderRadius: "0px",
    boxShadow: "24px 11px 25px -9px rgba(64, 64, 68, 1)",
  },
  text: {
    color: theme.palette.passwordIcon2.primary,
    fontSize: theme.typography.paragraph,
    letterSpacing: "1.5px",
    fontWeight: "700",
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  labelStrengthPassword: {
    display: "block",
    posistion: "relative",
    fontSize: "small",
    fontWeight: "500",
    width: "100%",
    color: theme.palette.text.primary2,
    textAlign: "left",
  },
  errorFormHint: {
    fontSize: theme.typography.hintText || 12,
    lineHeight: "1.4",
    color: theme.palette.error.main,
    textAlign: "left",
  },
}));

const PasswordResetMailPage = ({
  history,
  resettingByMailPassword,
  match: {
    params: { token },
  },
  location,
}) => {
  const classes = useStyles();
  const roles = useSelector((s) => s.roles);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);

  const [encodedToken, setEncodedToken] = useState(null);

  const dispatch = useDispatch();

  const role = parse(location.search.substring(1)).role;

  useEffect(() => {
    const t = JSON.parse(atob(decodeURIComponent(token)));
    setEncodedToken(t);

    const now = new Date();

    const tokenIsValid =
      new Date(t.validUntil ? t.validUntil : `${t.expires}Z`) > now;

    if (role === "user") {
      if (!tokenIsValid) {
        history.push("/password-reset-link-expired");
      } else {
        history.push(`/password-set-new/${token}?role=user`);
      }
    } else {
      if (!tokenIsValid) {
        history.push("/password-reset-link-expired");
      } else {
        history.push(`/password-set-new/${token}`);
      }
    }
  }, [token]);

  useEffect(() => {
    setFormIsValid(
      newPassword &&
        newPassword2 &&
        newPassword === newPassword2 &&
        passwordScore > 4
    );
  }, [newPassword, newPassword2, passwordScore]);

  useEffect(() => {
    if (!(roles[0] === "GUEST")) {
      // history.push("/password-reset-link-expired");
      roles.find((role) => role === "ROLE_USER")
        ? dispatch(logout({ isUser: true }))
        : dispatch(logout({ isUser: false }));
    }
    clearTimeout(typingTimer);
  }, []);

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  const submit = async (e) => {
    e.preventDefault();
    const success = await dispatch(
      resetByMailPassword({
        email: encodedToken.email,
        resetId: encodedToken.resetId,
        role,
        newPassword,
      })
    );

    if (success) {
      dispatch(
        alertAdd({
          text: "Hasło zostało zmienione",
          isSuccess: true,
        })
      );
      history.push(role === "user" ? "/login" : "/login-employee");
    } else {
      dispatch(
        alertAdd({
          text: "Błąd zmiany hasła",
          isError: true,
        })
      );
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push(role === "user" ? "/login" : "/login-employee");
  };

  const page = () => {
    return (
      <Box className={classes.root}>
        <Grid container style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
            <Paper className={classes.paper}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LockOpen style={{ marginRight: "5px", height: "25px" }} />
                <Typography className={classes.text}>Reset hasła</Typography>
              </Box>
              <Box m={1}>
                <CssTextField
                  required
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  fullWidth
                  id="new-password-1"
                  placeholder="Nowe hasło "
                  variant="outlined"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    testStrengthPassword(e);
                  }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility className={classes.iconColor} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onBlur={validateUserLogin}
                />
              </Box>
              <Box m={1}>
                <CssTextField
                  required
                  type={showNewPassword2 ? "text" : "password"}
                  value={newPassword2}
                  fullWidth
                  onChange={(e) => setNewPassword2(e.target.value)}
                  id="new-password-2"
                  // label="Powtórz hasło "
                  placeholder="Powtórz hasło"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword2(!showNewPassword2)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility className={classes.iconColor} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onChange={(e) => setUserLogin(e.target.value)}
                  // onBlur={validateUserLogin}
                />
              </Box>
              <Box mt={1} display="flex">
                <span className={classes.formHint}>
                  Aby spełnić zalożenie polityki Silnego Hasła prosi się o
                  podanie conajmniej jednej wielkiej litery, małej litery, cyfry
                  oraz znaku specjanlego. Hasło powinno być dłuższe niż 7
                  znaków.
                </span>
              </Box>
              <Box mt={1}>
                {passwordScore > 0 && (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className={classes.labelStrengthPassword}>
                      Siła hasła:
                    </Typography>
                    <span
                      className={s.strengthPassword}
                      dataScore={passwordScore}
                    />
                    {passwordMissingElements.length > 0 && (
                      <Typography
                        className={classes.errorFormHint}
                        style={{ marginTop: "5px" }}
                      >
                        Aby hasło było silne, należy zawrzeć:
                      </Typography>
                    )}
                    <span className={classes.errorFormHint}>
                      {passwordMissingElements.map((el, index) => {
                        return <li key={index}>{el}</li>;
                      })}
                    </span>
                  </Box>
                )}
              </Box>
              <Box
                mt={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box mb={1}>
                  <Button
                    variant={"contained"}
                    className={classes.locationButton}
                    onClick={submit}
                    disabled={!formIsValid}
                  >
                    ZRESETUJ HASŁO
                  </Button>
                </Box>
                <Box m={1}>
                  <Link className={classes.aMain} to="/login">
                    Anuluj
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <Can
      permission="password-reset-email:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  resettingByMailPassword: state.resettingByMailPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetByMailPassword: (newPassword) =>
    dispatch(resetByMailPassword(newPassword)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetMailPage);
