import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import * as classnames from "classnames";
import { ConfirmDialog } from "../../components/confirm-dialog";
import {
  fetchOrganizationTerms,
  removeTerm,
  uploadOrganizationTerm,
  fetchOrganizationCurrentTerms,
} from "../../redux/organization/actions";
import { alertAdd } from "../../redux/actions";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import { startOfDay } from "date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

const NO_ID_TO_REMOVE = -1;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const TermsAdminPanel = () => {
  const classes = useStyles();

  const [termName, setTermName] = useState("");
  const [termPath, setTermPath] = useState("");
  const [termFile, setTermFile] = useState();
  const [termsActiveSince, setTermsActiveSince] = useState();
  const terms = useSelector((s) => s.terms);

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const [formRef, setFormRef] = useState();

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    dispatch(fetchOrganizationTerms());
  }, []);

  const handleUploadedFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];

      let reader = new FileReader();

      reader.onloadend = () => {
        setTermFile(file);
        setTermPath(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const askToRemove = (formId) => {
    setIdToRemove(formId);
  };

  const remove = async (id) => {
    try {
      await dispatch(removeTerm(id));
      setIdToRemove(NO_ID_TO_REMOVE);
      await dispatch(fetchOrganizationTerms());
    } catch (error) {
      let errorMessage = `Błąd usuwania regulaminu, (${error.message})`;
      if (error.response.status === 400) {
        if (error.response.data.message === "cant-remove-used-terms") {
          errorMessage =
            "Błąd usuwania regulaminu, ktoś już zaakceptował ten regulamin.";
        }
      }
      dispatch(
        alertAdd({
          text: errorMessage,
          isError: true,
          timeout: 4000,
        })
      );
    }
  };

  const findTermsByActiveSinceAndNotRemoved = (activeSince) => {
    let foundTerm;
    if (terms) {
      foundTerm = terms.find(
        (term) =>
          new Date(term.activeSince).getTime() ===
            new Date(activeSince).getTime() && term.removedAt == null
      );
    }
    return foundTerm;
  };

  const submit = async (e) => {
    e.preventDefault();

    if (termName && termFile && termsActiveSince) {
      if (findTermsByActiveSinceAndNotRemoved(termsActiveSince) === undefined) {
        setBusy(true);

        await dispatch(
          uploadOrganizationTerm({
            termFile,
            name: termName,
            active: true,
            activeSince: termsActiveSince,
          })
        );
        formRef.reset();
        setTermName("");
        setTermFile(null);
        setTermsActiveSince("");
        await dispatch(fetchOrganizationTerms());
        setBusy(false);
      } else {
        dispatch(
          alertAdd({
            text:
              "Istnieje już regulamin rozpoczynający się od tej daty. Zmień datę rozpoczęcia obowiązywania regulaminu lub usuń istniejący regulamin.",
            isError: true,
            timeout: 4000,
          })
        );
      }
    }
  };

  // const cancel = (e) => {
  //   e.preventDefault();

  //   history.push("/profile");
  // };

  const isTermsValid = () => {
    return (
      termFile !== null &&
      termFile !== undefined &&
      termName !== null &&
      termName !== "" &&
      termsActiveSince !== undefined
    );
  };

  const handleActiveSinceChange = (date) => {
    setTermsActiveSince(new Date(startOfDay(date)).toISOString());
  };

  const clearFile = () => {
    setTermFile(null);
    setTermPath("");
  };

  return (
    <div style={{ padding: 10 }}>
      <Box p={1}>
        <Grid container direction="column">
          <Box>
            <Typography variant="h6" gutterBottom>
              Zarządzanie regulaminami
            </Typography>
          </Box>

          <hr />

          <Grid item lg={12} xl={8}>
            <form
              ref={(el) => setFormRef(el)}
              onSubmit={submit}
              noValidate
              autoComplete="off"
            >
              <Typography variant="subtitle1" component="h2">
                Dodaj regulamin
              </Typography>
              <Box mt={2}>
                <TextField
                  required
                  label="Nazwa regulaminu"
                  variant="outlined"
                  type="text"
                  value={termName}
                  fullWidth
                  onChange={(e) => setTermName(e.target.value)}
                />
              </Box>
              <Box mt={3}>
                <div>Wgraj plik regulaminu *</div>
                <input
                  style={{ display: "none" }}
                  id={`contained-button-file`}
                  type="file"
                  onChange={(event) => {
                    handleUploadedFiles(event.target.files);
                    event.target.value = null;
                  }}
                  accept="application/pdf"
                />
                <label htmlFor={`contained-button-file`}>
                  <Button variant="contained" component="span">
                    Wgraj
                  </Button>
                </label>
                {termFile && (
                  <span>
                    {` ${termFile.name} `}
                    <Button variant="text" onClick={() => clearFile()}>
                      Usuń plik
                    </Button>
                  </span>
                )}
              </Box>
              <Box mt={3}>
                <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Data rozpoczęcia obowiązywania regulaminu"
                    size="small"
                    margin="none"
                    format="dd/MM/yyyy"
                    value={termsActiveSince || null}
                    inputVariant="outlined"
                    onChange={(date) => handleActiveSinceChange(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    cancelLabel={"Anuluj"}
                    okLabel={"Zatwierdź"}
                    fullWidth
                    required
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <div style={{ marginTop: "2px" }}>* Pola wymagane</div>
              <Box mt={1} display="flex">
                <Button variant={"contained"} fullWidth>
                  Anuluj
                </Button>
                <Button
                  className={classnames({ "is-loading": busy })}
                  type="submit"
                  variant={"contained"}
                  disabled={!isTermsValid() || busy}
                  fullWidth
                  style={{
                    backgroundColor: !isTermsValid() ? "" : "blue",
                    color: !isTermsValid() ? "" : "white",
                  }}
                >
                  {busy && (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  )}
                  {!busy && "Dodaj regulamin"}
                </Button>
              </Box>
            </form>
          </Grid>
          <hr />
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="h1">
              Lista regulaminów
            </Typography>
            <br />

            <ConfirmDialogMUI
              handleClose={() => {
                setIdToRemove(NO_ID_TO_REMOVE);
              }}
              open={idToRemove !== NO_ID_TO_REMOVE}
              text={`Czy na pewno usunąć ten regulamin?`}
              yesAction={() => {
                remove(idToRemove);
                setIdToRemove(NO_ID_TO_REMOVE);
              }}
              noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
            />
            <TableContainer component={Paper}>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Nazwa</TableCell>
                    <TableCell align="right">Aktywny od</TableCell>
                    <TableCell align="right">Opcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {terms
                    .sort(
                      (t1, t2) =>
                        new Date(t2.activeSince).getTime() -
                        new Date(t1.activeSince).getTime()
                    )
                    .map((term, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="right"
                          // style={{
                          //   textDecoration:
                          //     term.removedAt !== null ? "line-through" : "",
                          // }}
                        >
                          {term.name}{" "}
                          <a
                            href={`/org-media/files${term.link}`}
                            target={"_blank"}
                          >
                            (podgląd)
                          </a>
                        </TableCell>
                        <TableCell align="right">
                          {term.activeSince
                            ? format(new Date(term.activeSince), "yyyy-MM-dd")
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {term.removedAt == null && (
                            <Tooltip placement="top" title={"Usuń regulamin"}>
                              <span>
                                <IconButton
                                  onClick={() => askToRemove(term.id)}
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TermsAdminPanel;
