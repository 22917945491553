import React, { useEffect, useState } from "react";
import {
  alertAdd,
  register_organization,
  join_organization,
  fetchOrgNames,
  registerPerson,
} from "../../redux/actions";
import { Link } from "react-router-dom";
import { fetchOrganizationCurrentTerms } from "../../redux/organization/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Box, Paper, useMediaQuery } from "@material-ui/core";
import { LockOpen, VisibilityOutlined as Visibility } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import s from "./index.module.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Link1 from "@material-ui/core/Link";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import { ReactComponent as HBLogo } from "../../assets/hb-tmp.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as CalendarIconYellow } from "../../assets/calendar-yellow.svg";
import StopIcon from "@material-ui/icons/Stop";

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    // textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  },
}))(Link1);

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  formHint: {
    fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.hintText,
    letterSpacing: "0.7px",
  },
  labelStrengthPassword: {
    display: "block",
    posistion: "relative",
    fontSize: "small",
    fontWeight: "500",
    width: "100%",
    color: theme.palette.text.primary2,
  },
  aMain: {
    color: theme.palette.passwordIcon.primary,
    "&:hover": {
      color: theme.palette.passwordIcon.primary,
      textDecoration: "underline",
    },
    letterSpacing: "1px",
    fontWeight: "600",
  },
  errorFormHint: {
    fontSize: theme.typography.hintText || 12,
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: theme.palette.error.main,
  },
  paperContainer: {
    borderRadius: "0px",
    boxShadow: "13px 18px 29px -9px rgba(82, 82, 92, 1)",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  a: {
    color: theme.palette.text.blueHeaderYellow,
    "&:hover": {
      color: theme.palette.text.blueHeaderYellow,
      textDecoration: "underline",
    },
  },
  formHint1: {
    // fontSize: theme.typography.hintText || 12,
    color: theme.palette.text.item,
    marginRight: "5px",
    letterSpacing: "0.7px",
  },
  paddingLarge: {
    padding: "32px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingMedium: {
    padding: "12px 64px 64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  paddingSmall: {
    padding: "12px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px",
    },
  },
  loginTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: "600",
    letterSpacing: "2px",
    color: theme.palette.text.alternative2,
  },
  boxLoginTitle: {
    borderTop: "1px solid #AFAEAE",
    background: theme.palette.background.loginBackground,
  },
  iconColor: {
    color: theme.palette.passwordIcon2.primary,
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const testEmailPattern = (mail) => {
  let polishChars1 = String(mail)
    .toLowerCase()
    .normalize("NFD")
    .search(/[\u0300-\u036f]/g);
  let polishChars2 = String(mail)
    .toLowerCase()
    .normalize("NFD")
    .search(/\u0142/g);

  return (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(mail).toLowerCase()
    ) &&
    (polishChars2 !== 0
      ? polishChars1 + polishChars2 < 0
      : polishChars1 + polishChars2 < -1)
  );
};

const errorMessageMap = {
  "email-not-unique": "Błąd rejestracji, email jest już używany w systemie.",
};

const RegisterOrganisation = ({
  creatingUser,
  createdUser,
  errorCreatingMessage,
  history,
  fetchOrgNames,
  // registerPerson,
  names,
  unsupportedBrowser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);
  //const [termList, setTermList] = useState([]);                     -----we don't need this since there is only one terms
  //const [allTermsAccepted, setAllTermsAccepted] = useState(false);
  const orgCurrentTerms = useSelector((s) => s.orgCurrentTerms);

  const [acceptedTerms, setAcceptedTerms] = useState(null);

  const [orgNames, setOrgNames] = useState([]);
  const [orgCode, setOrgCode] = useState("");
  const [showOrgCode, setShowOrgCode] = useState(false);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState("");

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );

  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const outlinedInputClasses = useOutlinedInputStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const theme = useTheme();
  const isLightGlobalTheme = useSelector((s) => s.globalTheme) === "light";

  useEffect(() => {
    fetchOrgNames();
  }, []);

  useEffect(() => {
    if (names) {
      setOrgNames(
        names.map(({ id: value, orgName: label }) => ({
          value,
          label,
        }))
      );
    }
  }, [names]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  ////////we don't need this since there is only one terms
  // useEffect(() => {
  //   setAllTermsAccepted(
  //     termList.reduce((all, current) => all && current.accepted, true)
  //   );
  // }, [termList]);

  // useEffect(() => {
  //   if (orgCurrentTerms) {
  //     setTermList(
  //       orgCurrentTerms.map((t) => ({
  //         ...t,
  //         accepted: false,
  //       }))
  //     );
  //   }
  // }, [orgCurrentTerms]);

  useEffect(() => {
    if (registrationCompleted) {
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
          isSuccess: true,
        })
      );
      history.push("/login");
    }
  }, [registrationCompleted]);

  const submit = async (e) => {
    e.preventDefault();

    setRecaptchaValid(false);

    if (formIsValid) {
      try {
        const birth = new Date(dateOfBirth);
        birth.setDate("1");

        const payload = {
          firstName,
          surname,
          email: mail.toLowerCase(),
          password,
          dateOfBirth: new Date(
            dateOfBirth.getTime() +
              Math.abs(dateOfBirth.getTimezoneOffset() * 60000)
          ),
          ...(acceptedTerms && { acceptedTerms }),
        };
        const r = await dispatch(registerPerson(payload));
        if (r) {
          setRegistrationCompleted(true);
        }
      } catch (e) {
        dispatch(
          alertAdd({
            text: errorMessageMap[e.response.data.message],
            isError: true,
          })
        );
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  useEffect(() => {
    async function fetchOrgCurrentTerms() {
      const result = await dispatch(fetchOrganizationCurrentTerms());
    }
    fetchOrgCurrentTerms();
    setAcceptedTerms(null);
  }, []);

  useEffect(() => {
    if (orgCurrentTerms?.id) {
      setFormIsValid(
        firstName &&
          surname &&
          testEmailPattern(mail) &&
          dateOfBirth &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          //allTermsAccepted &&
          acceptedTerms === orgCurrentTerms?.id &&
          passwordScore > 4
      );
    } else {
      setFormIsValid(
        firstName &&
          surname &&
          testEmailPattern(mail) &&
          dateOfBirth &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          //allTermsAccepted &&
          passwordScore > 4
      );
    }
  }, [
    firstName,
    surname,
    mail,
    passwordsMatch,
    password,
    recaptchaValid,
    //allTermsAccepted,
    acceptedTerms,
    orgCode,
    passwordScore,
    dateOfBirth,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    clearTimeout(typingTimer);
  }, []);

  const handleDateChange = (date) => {
    setDateOfBirth(date);
  };

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  return creatingUser ? (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Loader loading={creatingUser} text="Tworzenie Konta" />
    </Grid>
  ) : (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Grid item xs={12} sm={10} md={6} lg={5} xl={3}>
        <Box p={1}>
          {!creatingUser && (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid item xs={12}>
                  <Box>
                    <Paper
                      className={classes.paperContainer}
                      style={{
                        marginTop:
                          unsupportedBrowser && mediumScreen ? "90px" : "",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        className={classes.paddingLarge}
                      >
                        <HBLogo />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={`${classes.boxLoginTitle} ${classes.paddingLarge}`}
                      >
                        <StopIcon style={{ height: "8px", color: "#3458A4" }} />
                        <Typography className={classes.loginTitle}>
                          ZAREJESTRUJ SIĘ
                        </Typography>
                        <StopIcon style={{ height: "8px", color: "#23EAB6" }} />
                      </Box>
                      <Box className={classes.paddingSmall}>
                        <form onSubmit={submit} noValidate autoComplete="off">
                          <Box mt={1}>
                            <TextField
                              label={"* Imię"}
                              value={firstName}
                              fullWidth
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </Box>
                          <Box mt={1}>
                            <TextField
                              label={"* Nazwisko"}
                              value={surname}
                              fullWidth
                              onChange={(e) => setSurname(e.target.value)}
                            />
                          </Box>
                          <Box mt={1}>
                            <TextField
                              error={!(mail === "" || testEmailPattern(mail))}
                              helperText={
                                !(mail === "" || testEmailPattern(mail)) &&
                                "Nieprawidłowy adres email"
                              }
                              label={"* E-mail"}
                              value={mail}
                              fullWidth
                              onChange={(e) => setMail(e.target.value)}
                            />
                          </Box>
                          <Box mt={1}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={plLocale}
                            >
                              <KeyboardDatePicker
                                argin="normal"
                                id="date-of-birth-picker-dialog"
                                label="* data urodzenia"
                                format="MM/yyyy"
                                views={["year", "month"]}
                                value={dateOfBirth || null}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                required
                                cancelLabel={"Anuluj"}
                                okLabel={"Zatwierdź"}
                                maxDate={new Date()}
                                fullWidth
                                inputProps={{ readOnly: true }}
                                keyboardIcon={
                                  isLightGlobalTheme ? (
                                    <CalendarIcon />
                                  ) : (
                                    <CalendarIconYellow />
                                  )
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </Box>
                          <Box mt={1}>
                            <TextField
                              label="* Hasło"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              error={invalidPasswordInputChar}
                              helperText={
                                invalidPasswordInputChar
                                  ? "Spacja nie jest dozwolona."
                                  : ""
                              }
                              onChange={(e) => {
                                if (e.target.value.includes(" ")) {
                                  setInvalidPasswordInputChar(true);
                                  setTimeout(
                                    () => setInvalidPasswordInputChar(false),
                                    3000
                                  );
                                } else {
                                  setInvalidPasswordInputChar(false);
                                }
                                setPassword(e.target.value.split(" ").join(""));
                                testStrengthPassword(e);
                              }}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      aria-label="toggle password visibility"
                                    >
                                      <Visibility
                                        className={classes.iconColor}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box mt={1}>
                            <TextField
                              error={password2 && !passwordsMatch}
                              helperText={
                                password2 && !passwordsMatch
                                  ? "Powtórzone hasło nie jest zgodne"
                                  : ""
                              }
                              label="* Powtórz hasło"
                              type={showPassword ? "text" : "password"}
                              value={password2}
                              onChange={(e) =>
                                setPassword2(e.target.value.split(" ").join(""))
                              }
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      aria-label="toggle password visibility"
                                    >
                                      <Visibility
                                        className={classes.iconColor}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box mt={1}>
                            <span className={classes.formHint}>
                              * Pola wymagane
                            </span>
                          </Box>
                          <Box mt={1} display="flex">
                            <span className={classes.formHint}>
                              Aby spełnić zalożenie polityki Silnego Hasła prosi
                              się o podanie conajmniej jednej wielkiej litery,
                              małej litery, cyfry oraz znaku specjanlego. Hasło
                              powinno być dłuższe niż 7 znaków.
                            </span>
                          </Box>
                          {passwordScore > 0 && (
                            <div>
                              <label className={classes.labelStrengthPassword}>
                                Siła hasła:
                              </label>
                              <Box mt={1} display="flex">
                                <span
                                  className={s.strengthPassword}
                                  dataScore={passwordScore}
                                />
                              </Box>
                              {passwordMissingElements.length > 0 && (
                                <label
                                  className={classes.errorFormHint}
                                  style={{ marginTop: "10px" }}
                                >
                                  Aby hasło było silne, należy zawrzeć:
                                </label>
                              )}
                              <span className={classes.errorFormHint}>
                                {passwordMissingElements.map((el, index) => {
                                  return <li key={index}>{el}</li>;
                                })}
                              </span>
                            </div>
                          )}

                          {/* <Box mt={1}>
                              {termList && termList.length > 0 && (
                                <label>Akceptuję następujące regulaminy: </label>
                              )}{" "}
                            </Box> */}

                          {/* <Box>
                              {termList &&
                                termList.length > 0 &&
                                termList.map((term) => {
                                  return (
                                    <div key={term.id}>
                                      <input
                                        type="checkbox"
                                        checked={term.accepted}
                                        onChange={() => {
                                          setTermList(
                                            termList.map((acceptedTerm) =>
                                              acceptedTerm.id === term.id
                                                ? {
                                                    ...acceptedTerm,
                                                    accepted: !acceptedTerm.accepted,
                                                  }
                                                : acceptedTerm
                                            )
                                          );
                                        }}
                                        style={{ marginRight: "5px", marginLeft: "10px" }}
                                      />
                                      <span>
                                        <label style={{ marginRight: "8px" }}>
                                          {term.name}
                                        </label>
                                        <a
                                          href={`/org-media/files${term.link}`}
                                          target={"_blank"}
                                        >
                                          przeczytaj
                                        </a>
                                      </span>
                                    </div>
                                  );
                                })}{" "}
                            </Box> */}

                          {orgCurrentTerms && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!acceptedTerms}
                                  onChange={({ target: { checked } }) => {
                                    setAcceptedTerms(
                                      checked ? orgCurrentTerms.id : null
                                    );
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={
                                <Typography variant="body1">
                                  Akceptuję{" "}
                                  <MuiNavLink
                                    href={`/org-media/files${orgCurrentTerms.link}`}
                                    target="_blank"
                                  >
                                    {" "}
                                    regulamin
                                  </MuiNavLink>{" "}
                                  serwisu *
                                </Typography>
                              }
                            />
                          )}

                          <Box mt={3} mb={3}>
                            <ReCAPTCHA
                              style={{
                                transform: "scale(0.77)",
                                transformOrigin: "0 0",
                              }}
                              sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
                              onChange={onChange}
                            />
                          </Box>

                          <Box className={classes.paddingMedium}>
                            <Box display="flex" justifyContent={"center"}>
                              <Button
                                type="submit"
                                variant={"contained"}
                                color="primary"
                                style={{
                                  borderRadius: "0px",
                                  height: "50px",
                                  width: "250px",
                                }}
                                disabled={!formIsValid}
                              >
                                Zarejestruj się
                              </Button>
                            </Box>
                            <Box
                              mt={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <span className={classes.formHint1}>
                                {"Masz już konto?  "}
                              </span>
                              {/* <Box textAlign="right" fontSize={12} m={1}> */}
                              <Link className={classes.aMain} to="/login">
                                Zaloguj się
                              </Link>
                            </Box>
                          </Box>
                        </form>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  creatingUser: state.creatingUser,
  createdUser: state.createdUser,
  errorCreatingMessage: state.errorCreatingMessage,
  names: state.orgNames,
  unsupportedBrowser: state.unsupportedBrowser,
});

const mapDispatchToProps = (dispatch) => ({
  // registerPerson: () => dispatch(registerPerson()),
  fetchOrgNames: () => dispatch(fetchOrgNames()),
  fetchOrganizationCurrentTerms: () =>
    dispatch(fetchOrganizationCurrentTerms()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterOrganisation);
