import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getAxiosInstance } from "../../redux/common";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
// import TestStandModal from "./modal/test-stand-modal";
import { Alert } from "@material-ui/lab";
import TestStandRow from "./test-stand-row";
import TestStandModal from "./modal/test-stand-modal";
import FilterProperty from "../../components/filter-property/filter-property";
import { FilterPropertySelect } from "../../components/filter-property/filter-property";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
}));

const TestStandsAdminPanel = () => {
  const tenantsContext = useSelector((s) => s.tenantsContext || []);
  const [tmpContext, setTmpContext] = useState("");
  const [testStands, setTestStands] = useState([]);
  const [unfilteredTestStands, setUnfilteredTestStands] = useState([]);
  const [fetchingTestStands, setFetchingTestStands] = useState(false);

  const classes = useStyles();

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("label");

  const [filterQueries, setFilterQueries] = useState({});
  const [statsFilterOpen, setStatsFilterOpen] = useState(false);

  const [openTestStandModal, setOpenTestStandModal] = useState(false);
  const [testStandsHandlerType, setTestStandsHandlerType] = useState("edition");
  const [testStandToEdit, setTestStandsToEdit] = useState({});
  const [savedTestStand, setSavedTestStand] = useState(false);

  const [testStandToRemove, setTestStandToRemove] = useState({});
  const [openTestStandRemovePanel, setOpenTestStandRemovePanel] = useState(
    false
  );
  const [displaySearchButton, setDisplaySearchButton] = useState(false);

  const headerCells = [
    {
      title: "Stanowisko",
      key: "label",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Lokalizacja",
      key: "city",
      sortable: true,
      filter: (key) => (
        <FilterProperty
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Typ stacji",
      key: "stationType",
      sortable: true,
      filter: (key) => (
        <FilterPropertySelect
          label={"Rodzaj"}
          listItems={[
            { value: "Open", label: "Otwarty" },
            { value: "Closed", label: "Zamknięty" },
          ]}
          filterProperty={key}
          setFilterQueries={setFilterQueries}
          filterQueries={filterQueries}
        />
      ),
    },
    {
      title: "Opcje",
      key: "options",
      sortable: false,

      filter: (key) => (
        <>
          <Box>
            {displaySearchButton && (
              <Button
                color="primary"
                // size="large"
                startIcon={<SearchIcon />}
                onClick={searchFilterHandler}
              >
                Szukaj
              </Button>
            )}
            {Object.keys(filterQueries).length !== 0 && (
              <Button
                color="primary"
                // size="large"
                startIcon={<ClearIcon />}
                onClick={clearAllFilterHandler}
              >
                <Tooltip title="Czyści wszystkie pola filtracji">
                  <span>Wyczyść</span>
                </Tooltip>
              </Button>
            )}
          </Box>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (tenantsContext?.length === 1) {
      setTmpContext(tenantsContext[0].tenantId);
    }
  }, []);

  useEffect(() => {
    if (tmpContext) {
      loadTestStands();
    } else if (!tmpContext && tenantsContext.length == 0) {
      loadTestStands();
    }
  }, [tmpContext]);

  useEffect(() => {
    if (savedTestStand) {
      loadTestStands();
    }
  }, [savedTestStand]);

  useEffect(() => {
    const arrayTestStands = [...testStands];
    let sortParam = ``;
    if (orderBy !== "stationType") {
      sortParam = `${order === "desc" ? "-" : ""}${orderBy}`;
    } else {
      sortParam = `${order === "asc" ? "-" : ""}${orderBy}`;
    }
    setTestStands(arrayTestStands.sort(dynamicSort(sortParam)));
  }, [order, orderBy]);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property].toLowerCase() < b[property].toLowerCase()
          ? -1
          : a[property].toLowerCase() > b[property].toLowerCase()
          ? 1
          : 0;
      return result * sortOrder;
    };
  };

  useEffect(() => {
    setDisplaySearchButton(true);
  }, [filterQueries]);

  const loadTestStands = async () => {
    try {
      setFetchingTestStands(true);
      const response = await getAxiosInstance().get(
        "/screening/v1/hearbox/station/all",
        {
          params: {
            ...(tmpContext && {
              tenantId: tmpContext,
            }),
          },
        }
      );
      setFetchingTestStands(false);
      const testStand = response.data;
      setTestStands(
        testStand.sort(dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`))
      );
      setUnfilteredTestStands(testStand);
    } catch (e) {
      setFetchingTestStands(false);
      setTestStands([]);
    }
  };

  const searchFilterHandler = () => {
    setDisplaySearchButton(false);
    const result = unfilteredTestStands.filter(search, filterQueries);
    setTestStands(result);
  };

  const clearAllFilterHandler = () => {
    setFilterQueries({});
    setDisplaySearchButton(false);
    const result = unfilteredTestStands.sort(
      dynamicSort(`${order === "desc" ? "-" : ""}${orderBy}`)
    );
    setTestStands(result);
  };

  function search(screening) {
    return Object.keys(this).every((key) => {
      if (key !== "stationType") {
        return screening[key]
          .toString()
          .toLowerCase()
          .includes(this[key].toLowerCase());
      } else if (key === "stationType") {
        return screening[key] === this[key];
      }
    });
  }

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const orgAdditionButtonHandler = () => {
    setTestStandsHandlerType("add");
    setOpenTestStandModal(true);
  };

  const testStandRemoveHandler = async () => {
    try {
      const response = await getAxiosInstance().delete(
        "/screening/v1/hearbox/station",
        {
          params: {
            hearBoxId: testStandToRemove.hearBoxId,
          },
        }
      );
      setTestStandToRemove({});
      loadTestStands();
    } catch (e) {
      console.log(e);
    }
  };

  return fetchingTestStands ? (
    <Loader loading={true} text="Pobieranie danych" />
  ) : tenantsContext.length > 1 && !tmpContext ? (
    <Box p={3} style={{ height: "100vh" }}>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="open-role-filter-label">
              Kontekst stanowiska organizacji
            </InputLabel>
            <Select
              labelId="open-role-filter-label"
              id="open-role-filter"
              label={"Kontekst stanowiska organizacji"}
              onChange={(e) => {
                setTmpContext(e.target.value);
              }}
              value={tmpContext}
            >
              {tenantsContext.map((v) => (
                <MenuItem value={v.tenantId} key={v.tenantId}>
                  {v.orgName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <div style={{ padding: 10 }}>
      <Button variant={"contained"} onClick={orgAdditionButtonHandler}>
        Dodaj nowe stanowisko
      </Button>
      {tmpContext.length > 0 && (
        <Box p={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="open-role-filter-label">
              Kontekst stanowiska organizacji
            </InputLabel>
            <Select
              labelId="open-role-filter-label"
              id="open-role-filter"
              label={"Kontekst stanowiska organizacji"}
              onChange={(e) => {
                setTmpContext(e.target.value);
              }}
              value={tmpContext}
            >
              {tenantsContext.map((v) => (
                <MenuItem value={v.tenantId} key={v.tenantId}>
                  {v.orgName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="Test stands list">
          <TableHead>
            <TableRow>
              {headerCells.map((headCell) =>
                headCell.sortable ? (
                  <TableCell
                    key={headCell.key}

                    // sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Tooltip title="Sortuj">
                      <TableSortLabel
                        active={orderBy === headCell.key}
                        direction={orderBy === headCell.key ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(headCell.key);
                        }}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell>{headCell.title}</TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {headerCells.map((headCell) => (
                <TableCell key={headCell.key}>
                  {headCell.filter(headCell.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {testStands.map((testStand, index) => (
              <TestStandRow
                key={index}
                testStandData={testStand}
                setOpenTestStandModal={setOpenTestStandModal}
                setTestStandsToEdit={setTestStandsToEdit}
                setTestStandsHandlerType={setTestStandsHandlerType}
                setOpenTestStandRemovePanel={setOpenTestStandRemovePanel}
                setTestStandToRemove={setTestStandToRemove}
              />
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={testStands.length}
          // count={testStandsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={"Wierszy na stronę:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} z ${count}`
          }
          className={classes.select}
        /> */}
      </TableContainer>
      {openTestStandModal && (
        <TestStandModal
          openTestStandModal={openTestStandModal}
          testStandsHandlerType={testStandsHandlerType}
          handleClose={() => {
            setOpenTestStandModal(false);
          }}
          testStandToEdit={
            testStandsHandlerType === "add" ? {} : testStandToEdit
          }
          setSavedTestStand={setSavedTestStand}
        />
      )}
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenTestStandRemovePanel(false);
        }}
        open={openTestStandRemovePanel}
        text={"Czy na pewno usunąć stanowisko " + testStandToRemove.label + "?"}
        yesAction={testStandRemoveHandler}
        noAction={() => {
          setTestStandToRemove({});
        }}
      />
    </div>
  );
};

export default TestStandsAdminPanel;
