import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  CircularProgress,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { getAxiosInstance } from "../../../redux/common";
import Loader from "../../../components/loader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import { normalizeZIP } from "../../../components/address-form/shipping-info";
import PageTitle from "../../../components/page-title";
import FormEditor from "../../../components/form-editor/form-editor";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

function getModalStyle() {
  const top = 50;
  const left = 10;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.alternative,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width: 600px)": {
      padding: "20px",
    },
    // "@media (max-width: 600px)": {
    //   height: "100vh",
    // },
    // height: "70vh",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.text.item,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.item,
    },
  },
}));

const FormModal = ({
  openFormModal,
  //   testStandsHandlerType,
  handleClose,
  //   testStandToEdit,
  //   setSavedTestStand,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [busy, setBusy] = useState(false);

  const [formData, setFormData] = useState();
  const [initialRows, setInitialRows] = useState();
  const [formEdited, isFormEdited] = useState(false);

  const title = <Box></Box>;
  const body = (
    <Box>
      <Box p={1}>
        {1 ? (
          <PageTitle title={"Nowy formularz"} />
        ) : 1 ? (
          <PageTitle title={`Tryb podglądu ankiety: test`} />
        ) : (
          //   <PageTitle title={`Tryb podglądu formularza: ${name}`} />
          <PageTitle title={`Edycja test`} />
          //   <PageTitle title={`Edycja ${name}`} />
        )}
      </Box>

      <Box p={1}>
        <label htmlFor="form-name">Nazwa formularza</label>

        <input
          //   disabled={displayMode}
          id="form-name"
          type="text"
          className="input"
          placeholder="np. Dane osobowe"
          //   value={name}
          //   onChange={({ target: { value: name } }) => {
          //     setName(name);
          //     isFormEdited(true);
          //   }}
        />
      </Box>

      <Box p={1}>
        <label htmlFor="form-name">Opis formularza</label>

        <input
          //   disabled={displayMode}
          id="form-description"
          type="text"
          className="input"
          //   value={description}
          //   onChange={({ target: { value: name } }) => {
          //     setDescription(name);
          //     isFormEdited(true);
          //   }}
        />
        <hr />
      </Box>

      <Box p={1}>
        <FormEditor
          displayMode={false}
          onUpdate={setFormData}
          //   formId={id} from params
          initialRows={initialRows}
          formEdited={isFormEdited}
        />
        <hr />
      </Box>
    </Box>
  );

  const foot = (
    <Box>
      {" "}
      <Button variant={"contained"} onClick={() => handleClose()}>
        Anuluj
      </Button>
    </Box>
  );

  return (
    <Modal open={openFormModal} className={classes.modalStyle}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <Paper className={classes.paper}>
            {" "}
            {title} <hr />
            {body}
            <hr
              style={{
                margin: "24px 0px",
              }}
            />
            {foot}
          </Paper>
        </Grid>
      </Grid>
      {/* 
       
      </Box> */}
    </Modal>
  );
};
export default FormModal;
