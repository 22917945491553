import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardContent,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  spacing: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    boxShadow: "none",
  },
  root: {
    "& .appear-item": {
      display: "none",
    },
    "&:hover .appear-item": {
      display: "block",
      position: "absolute",
      zIndex: "100",
    },
  },
}));

const stationTypeMap = {
  Open: "Otwarty",
  Closed: "Zamknięty",
};

const TestStandRow = ({
  testStandData,
  setOpenTestStandModal,
  setTestStandsToEdit,
  setTestStandsHandlerType,
  setTestStandToRemove,
  setOpenTestStandRemovePanel,
}) => {
  const {
    label,
    city,
    stationType,
    voivodeship,
    country,
    street,
    postCode,
  } = testStandData;
  const classes = useStyles();

  const testStandEditionHandler = () => {
    setTestStandsHandlerType("edition");
    setTestStandsToEdit(testStandData);
    setOpenTestStandModal(true);
  };

  return (
    <TableRow>
      <TableCell>{label || "---"}</TableCell>
      <TableCell>
        <div>
          {city || "---"}{" "}
          <span className={classes.root}>
            <IconButton>
              <InfoIcon />
            </IconButton>
            <Card className="appear-item">
              <CardContent>
                <Typography variant="body2">Kraj: {country}</Typography>
                <Typography variant="body2">
                  Województwo: {voivodeship}
                </Typography>
                <Typography variant="body2">Miasto: {city}</Typography>
                <Typography variant="body2">Ulica: {`${street}`}</Typography>
                <Typography variant="body2">
                  Kod pocztowy: {`${postCode}`}
                </Typography>
                <Typography variant="body2"></Typography>
              </CardContent>
            </Card>
          </span>
        </div>
      </TableCell>
      <TableCell>{stationTypeMap[stationType] || "---"}</TableCell>
      <TableCell>
        <ButtonGroup
          className={classes.spacing}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            variant={"contained"}
            color="primary"
            onClick={testStandEditionHandler}
          >
            Konfiguruj
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            onClick={() => {
              setTestStandToRemove(testStandData);
              setOpenTestStandRemovePanel(true);
            }}
          >
            Usuń
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

export default TestStandRow;
