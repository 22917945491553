import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
} from "@material-ui/core";
import { getAxiosInstance } from "../../redux/common";
import Loader from "../../components/loader";
import UserRow from "./user-row";
import UserModal from "./modal/user-modal";
import { Alert, TabPanel } from "@material-ui/lab";
import PatientPanel from "./patient";
import OrganizationModal from "../organizations/modal/organization-modal";
import FilterProperty, {
  FilterPropertySelect,
} from "../../components/filter-property/filter-property";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import SearchIcon from "@material-ui/icons/Search";
import EmployeePanel from "./employee";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: "1.5rem",
    marginTop: 10,
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const UsersAdminPanel = () => {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  const orgRegisterButtonHandler = () => {
    setOpenOrganizationModal(true);
  };

  return (
    <div style={{ padding: 10 }}>
      <div className={classes.root}>
        {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {" "}
          <Tab label="Pacjenci" />
          <Tab label="Lekarze" />
        </Tabs>
        <Box hidden={value !== 0}>
          {" "}
          <PatientPanel />
        </Box>
        <Box p={2} hidden={value !== 1}>
          <EmployeePanel />
        </Box>
      </div>
      {/* {openOrganizationModal && (
      <OrganizationModal
        openOrganizationModal={openOrganizationModal}
        organizationHandlerType={"register"}
        handleClose={() => {
          setOpenOrganizationModal(false);
        }}
        loadOrganizations={loadUsers}
        setRegisterCompleted={setAdditionCompleted}
      />
    )} */}
    </div>
  );
  //  fetchingUsers || removingUser ? (
  //   <Loader
  //     loading={true}
  //     text={fetchingUsers ? "Pobieranie użytkowników" : "Usuwanie użytkownika"}
  //   />
  // ) : (

  // );
};

export default UsersAdminPanel;
