import React from "react";
import classnames from "classnames";
import s from "../../pages/register-organization/index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
}));

const Loader = ({ loading, text, size = "3x" }) => {
  const classes = useStyles();
  if (loading) {
    return (
      <div className={`columns is-centered ${classes.fontSize}`}>
        <div className={classnames("column is-half", s.centered)}>
          <span>{text}</span>
          <br />
          <br />
          <br />
          <FontAwesomeIcon icon="sync-alt" spin={true} size={size} />
        </div>
      </div>
    );
  }
  return null;
};

export default Loader;
